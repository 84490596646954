<div class="container-fluid px-0">
  <div class="row mt-4" style="position: absolute; width: 100%">
    <div class="col-6 col-md-4 col-lg-6">
      <a href="/">
        <img
          width="1"
          height="1"
          src="/assets/images/vtc.png"
          alt="logo-prinicpal"
          class="logo-principal"
        />
      </a>
      <h1 style="position: absolute; opacity: 0">Sobre Nosotros</h1>
    </div>
    <div class="d-none d-md-block col-md-8 col-lg-6 text-white text-right blur-background" style="text-align: right; padding-right: 20px; ">
      <span style="text-align: right; color: white; text-decoration: none;">
      <a href="/home" style="color: white; text-decoration: none;">
        Inicio
        <span style="margin-left: 15px;">|</span>
      </a>
      @if( baseUrl.includes('ec.visatravelcenter') || baseUrl.includes('//visatravelcenter.com')){
        <a href="https://ec.visatravelcenter.com/asesoria-migratoria" style="color: white; text-decoration: none;  margin-left: 15px">
          Asesoría Según tu Destino
          <span style="margin-left: 15px;">|</span>
        </a>
        <a href="https://ec.visatravelcenter.com/asesoria-migratoria-estudios-exterior" style="color: white; text-decoration: none; margin-left: 15px">
          Asesoría Para Estudios
          <span style="margin-left: 15px;">|</span>
        </a>
      }
      @if( baseUrl.includes('co.visatravelcenter') ){
        <a href="https://co.visatravelcenter.com/asesoria-migratoria" style="color: white; text-decoration: none; margin-left: 15px">
          Asesoría Según tu Destino
          <span style="margin-left: 15px;">|</span>
        </a>
        <a href="https://co.visatravelcenter.com/asesoria-migratoria-estudios-exterior" style="color: white; text-decoration: none; margin-left: 15px">
          Asesoría Para Estudios
          <span style="margin-left: 15px;">|</span>
        </a>
      }

      @if( baseUrl.includes('go.visatravelcenter') || baseUrl.includes('localhost')){
        <a href="https://go.visatravelcenter.com/asesoria-migratoria" style="color: white; text-decoration: none; margin-left: 15px">
          Asesoría Según tu Destino
          <span style="margin-left: 15px;">|</span>
        </a>
        <a href="https://go.visatravelcenter.com/asesoria-migratoria-estudios-exterior" style="color: white; text-decoration: none; margin-left: 15px">
          Asesoría Para Estudios
          <span style="margin-left: 15px;">|</span>
        </a>
      }
      <a href="/blog" style="color: white; text-decoration: none; margin-left: 15px">
        Blog
        <span style="margin-left: 15px;">|</span>
      </a>

      @if( baseUrl.includes('ec.visatravelcenter') || baseUrl.includes('go.visatravelcenter') || baseUrl.includes('//visatravelcenter.com') || baseUrl.includes('localhost')){
        <a href="https://api.whatsapp.com/send?phone=593961459059&text=Quiero%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20asesorias%20migratorias!" target="_blank" class="footer-link" style="color: white; text-decoration: none;">
          <img
          width="1"
          height="1"
          src="/assets/images/whatsapp.png"
          alt="whatsappnicpal"
          style="width:16px; height: auto; margin-left: 30px; display: inline-block;"
        />
          +593 961 459 059
          <img
            width="1"
            height="1"
            src="/assets/images/ecuador.png"
            alt="banner-prinicpal"
            style="width:16px; height: auto; margin-top: -5px; margin-right: 10px"
          />
        </a>
      }
      @if( baseUrl.includes('co.visatravelcenter')){
        <a href="https://api.whatsapp.com/send?phone=573125501225&text=Quiero%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20asesorias%20migratorias!" target="_blank" class="footer-link">
          <img
            width="1"
            height="1"
            src="/assets/images/whatsapp.png"
            alt="whatsappnicpal"
            style="width:16px; height: auto; margin-left: 30px; display: inline-block;"
          />
          +57 312 55 01 225
          <img
          width="1"
          height="1"
          src="/assets/images/colombia.png"
          alt="whatsappnicpal"
          style="width:16px; height: auto; margin-top: -5px"
        /></a>
      }</span>
    </div>
    @if( baseUrl.includes('ec.visatravelcenter') || baseUrl.includes('go.visatravelcenter') || baseUrl.includes('//visatravelcenter.com') || baseUrl.includes('localhost')){
      <div class="col-6 d-block d-md-none text-right">
        <a target="_blank" href="https://api.whatsapp.com/send?phone=593961459059&amp;text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20Asesoria%20Migratoria">
          <img
            width="1"
            height="1"
            src="/assets/images/ws-mobile.png"
            alt="contacto whatsapp"
            style="height: 30px; width: auto; margin-top: 5px;"
            class=""
          />
        </a>
      </div>
    }
    @if( baseUrl.includes('co.visatravelcenter')){
      <div class="col-6 d-block d-md-none text-right">

        <a target="_blank" href="https://api.whatsapp.com/send?phone=573125501225&amp;text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20Asesoria%20Migratoria">
          <img
            width="1"
            height="1"
            src="/assets/images/ws-mobile.png"
            alt="contacto whatsapp"
            style="height: 30px; width: auto; margin-top: 5px;"
            class=""
          />
        </a>
      </div>
    }
  </div>
  <img
    width="1"
    height="1"
    src="/assets/images/politics-main.png"
    alt="banner-prinicpal"
    style="width: 100%; height: auto;"
    class="d-none d-md-block"
  />
  <img
    width="1"
    height="1"
    src="/assets/images/politics-mobile.png"
    alt="banner-prinicpal"
    style="width: 100%; height: auto;"
    class="d-block d-md-none"
  />
</div>

<div class="container">
  <div class="row px-3 px-md-5">
    <div class="about-us-box mt-5 mb-5 px-md-5">
      <p class="about-us">
        <span style="font-weight: 600; font-size: 30px">Políticas del Sitio web</span> <br><br>
        <span style="font-weight: 600;  font-size: 22px">Acceso al Sitio Web</span><br><br>
        El acceso al sitio web del Centro de Viajes está restringido exclusivamente a usuarios autorizados.
        Para acceder a ciertas áreas del sitio web, se requiere autenticación, lo que implica el uso de
        credenciales de acceso únicas y seguras. Es responsabilidad de cada usuario proteger
        adecuadamente sus credenciales de acceso, evitando compartir información confidencial con
        terceros y siguiendo las pautas de seguridad establecidas por el Centro de Viajes.<br><br>
        El acceso al sitio web se limita al personal de Visa Trevel Center y a otros individuos designados
        para fines específicos relacionados con la misión del Centro de Viajes. Esto incluye a los empleados
        del área de Tecnologías de Información y Comunicación (TIC), administradores de sistemas,
        desarrolladores web, personal de marketing y comunicaciones, así como a cualquier otro individuo
        autorizado por la administración del Centro de Viajes para propósitos específicos relacionados con
        la gestión y operación del sitio web.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Uso Aceptable.</span><br><br>
        El sitio web de Visa Travel Center debe utilizarse exclusivamente para actividades relacionadas con
        las operaciones y la misión del Centro de Viajes. Esto incluye, pero no se limita a, la promoción de
        servicios de atención, la difusión de información de servicios de viaje, la comunicación interna
        entre el personal del Centro de Viajes y la comunicación externa con usuarios, proveedores y otros
        stakeholders pertinentes.<br><br>
        Se prohíbe estrictamente cualquier otro uso del sitio web del Centro de Viajes Visa Travel Center
        que no esté directamente relacionado con sus funciones institucionales. Esto incluye, pero no se
        limita a, el uso del sitio web para fines comerciales, políticos o personales. Además, cualquier
        actividad que viole las leyes y regulaciones locales, nacionales o internacionales está
        terminantemente prohibida.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Contenido del Sitio Web.</span><br><br>
        Todo el contenido publicado en el sitio web del Centro de Viajes debe ser preciso, actualizado y
        relevante. Los administradores del sitio web son responsables de garantizar la integridad y la
        calidad del contenido, así como de corregir cualquier información obsoleta o incorrecta. Se
        prohíbe la publicación de contenido no autorizado o que pueda dañar la reputación del Centro de
        Viajes Visa Travel Center.<br><br>
        Los administradores del sitio web deben asegurarse de que toda la información proporcionada en
        el sitio web del Centro de Viajes sea precisa y esté al día. Esto incluye revisar regularmente el
        contenido existente para detectar posibles errores o información desactualizada y realizar las
        correcciones necesarias de manera oportuna.<br><br>
        Además, se deben evitar publicar contenidos no autorizados que puedan comprometer la
        integridad del sitio web o la reputación del Centro de Viajes. Esto incluye información confidencial,
        material protegido por derechos de autor sin permiso, o cualquier contenido que viole las políticas
        internas del Centro de Viajes o las leyes y regulaciones aplicables.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Protección de la Propiedad Intelectual.</span><br><br>
        Se prohíbe la publicación de contenido protegido por derechos de autor sin autorización legal. Se
        deben respetar los derechos de autor y propiedad intelectual al utilizar imágenes, textos, videos y
        otros materiales en el sitio web del Centro de Viajes. Los usuarios son responsables de obtener los
        permisos necesarios para cualquier material utilizado.<br><br>
        Todo el contenido utilizado en el sitio web del Centro de Viajes debe ser legalmente obtenido y
        utilizado de acuerdo con las leyes de derechos de autor y propiedad intelectual. Se prohíbe el uso
        de materiales protegidos por derechos de autor sin el permiso adecuado del propietario de los
        derechos.<br><br>
        Los usuarios son responsables de obtener los permisos necesarios para cualquier material
        protegido por derechos de autor utilizado en el sitio web del Centro de Viajes. Esto incluye
        imágenes, textos, videos y cualquier otro contenido que esté sujeto a derechos de autor. Se
        recomienda que los usuarios busquen permiso explícito y documenten cualquier autorización
        recibida para evitar posibles infracciones legales.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Interacciones en Línea.</span><br><br>
        Se espera que todos los usuarios del sitio web del Centro de Viajes se comporten de manera
        respetuosa y profesional en todas las interacciones en línea. Se prohíben los comportamientos
        inapropiados, como el acoso, la discriminación y la difamación. Los usuarios deben seguir las
        políticas de conducta en línea establecidas por el Centro de Viajes.<br><br>
        Las interacciones en línea deben mantener un tono respetuoso y constructivo en todo momento.
        Se prohíbe cualquier forma de acoso, intimidación, discriminación o difamación dirigida a otros
        usuarios, empleados del Centro de Viajes Visa Travel Center o cualquier otra persona.<br><br>
        Los usuarios deben abstenerse de publicar contenido que pueda ser considerado ofensivo,
        provocativo, difamatorio o que viole los derechos de otras personas. Además, se espera que los
        usuarios respeten la privacidad y los derechos de propiedad intelectual de otros usuarios y
        terceros en todas las interacciones en línea.<br><br>
        Cualquier violación de estas normas puede resultar en medidas disciplinarias, incluida la
        suspensión o revocación del acceso al sitio web del Centro de Viajes Visa Travel Center y acciones
        legales según la gravedad de la infracción y las políticas internas del Centro de Viajes.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Cumplimiento Legal.</span><br><br>
        Todos los usuarios del sitio web del Centro de Viajes Visa Travel Center deben cumplir con las
        leyes y regulaciones aplicables, incluyendo la Ley Orgánica de Protección de Datos Personales.<br><br>
        Es responsabilidad de cada usuario conocer y cumplir con estas leyes en el contexto de su uso del
        sitio web del Centro de Viajes. Esto incluye asegurarse de que cualquier información confidencial o
        sensible que se maneje a través del sitio web esté protegida de acuerdo con los estándares legales
        y las mejores prácticas de la industria.<br><br>
        Además, los usuarios deben ser conscientes de las implicaciones legales de sus acciones en línea,
        como la publicación de contenido protegido por derechos de autor sin autorización legal o la
        divulgación no autorizada de información protegida.<br><br>
        El incumplimiento de estas leyes y regulaciones puede resultar en consecuencias legales graves
        tanto para los usuarios individuales como para el Centro de Viajes en su conjunto. Por lo tanto, es
        fundamental que todos los usuarios se adhieran estrictamente a los requisitos legales y éticos al
        utilizar el sitio web del Centro de Viajes.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Sanciones por Incumplimiento.</span><br><br>
        El incumplimiento de esta política puede resultar en medidas disciplinarias proporcionales a la
        gravedad de la infracción y de acuerdo con las políticas internas del Centro de Viajes y las leyes
        laborales aplicables. Estas medidas disciplinarias pueden incluir, pero no se limitan a, la suspensión
        o terminación del acceso al sitio web del Centro de Viajes.<br><br>
        Además de las medidas disciplinarias internas, el Centro de Viajes se reserva el derecho de tomar
        acciones legales según lo considere necesario. Esto puede incluir la presentación de cargos legales
        contra el individuo responsable por cualquier daño causado al Centro de Viajes o a terceros como
        resultado del incumplimiento de esta política.<br><br>
        Es importante que todos los usuarios del sitio web del Centro de Viajes entiendan las posibles
        consecuencias del incumplimiento de esta política y tomen medidas para cumplir con sus
        disposiciones en todo momento. La cooperación y el cumplimiento son fundamentales para
        mantener la integridad y la seguridad del sitio web del Centro de Viajes y proteger la información
        confidencial y sensible que contiene.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Modificaciones a la Política.</span><br><br>
        El Centro de Viajes se reserva el derecho de modificar esta política en cualquier momento para
        reflejar cambios en las leyes, regulaciones o prácticas de la industria relacionadas con el uso del
        sitio web. Se notificará a los usuarios sobre cualquier cambio en las pautas de uso del sitio web a
        través de comunicaciones internas o publicaciones en el sitio web.<br><br>
        Es responsabilidad de todos los usuarios revisar periódicamente esta política para estar al tanto de
        las actualizaciones y comprender sus obligaciones continuas en relación con el uso del sitio web
        del Centro de Viajes. Se espera que los usuarios estén familiarizados con las disposiciones de esta
        política y cumplan con sus requisitos en todo momento.<br><br>

        <span style="font-weight: 600;  font-size: 22px">USO DEL SITIO WEB</span><br><br>
        El sitio web de Visa Travel Center está dedicado exclusivamente a proporcionar información sobre
        los servicios ofrecidos, así como recursos relacionados con la atención. Al acceder y utilizar este
        sitio web, aceptas cumplir con los siguientes términos y condiciones:<br><br>

        ● <span style="font-weight: 600;">Propósito del Sitio Web:</span> Este sitio web tiene como objetivo informar a los usuarios sobre
        los servicios disponibles en el Centro de Viajes, así como ofrecer recursos útiles
        relacionados con viajes y asesorías. Cualquier otro uso del sitio web que no esté
        relacionado con estos propósitos está prohibido.<br><br>
        ● <span style="font-weight: 600;">Cumplimiento Legal:</span> No utilices el sitio web para ningún propósito ilegal o no autorizado.
        Debes cumplir con todas las leyes y regulaciones locales, nacionales e internacionales
        aplicables al utilizar este sitio web.<br><br>
        ● <span style="font-weight: 600;">Integridad del Sitio Web:</span> No interfieras con el funcionamiento normal del sitio web ni
        intentes acceder a áreas protegidas del mismo sin autorización expresa. No realices
        acciones que puedan dañar, sobrecargar o comprometer la seguridad del sitio web.<br><br>
        ● <span style="font-weight: 600;">Contenido Aceptable:</span> No utilices el sitio web para difundir contenido que sea ofensivo,
        difamatorio, obsceno, discriminatorio o que viole cualquier ley aplicable. Esto incluye,
        pero no se limita a, contenido que promueva el odio, la violencia, la discriminación o la
        violación de la privacidad de otros.<br><br>
        ● <span style="font-weight: 600;">Respeto por la Privacidad:</span> Respeta la privacidad de otros usuarios y no publiques
        información personal o confidencial sobre ellos sin su consentimiento explícito.<br><br>
        ● <span style="font-weight: 600;">Uso de Recursos:</span> Utiliza los recursos del sitio web de manera responsable y ética. Evita el
        uso excesivo de ancho de banda, almacenamiento o capacidad de procesamiento que
        pueda afectar negativamente la experiencia de otros usuarios.<br><br>

        Al utilizar el sitio web de Visa Travel Center, aceptas cumplir con estos términos y condiciones de
        uso aceptable. El incumplimiento de estas reglas puede resultar en la suspensión o terminación de
        tu acceso al sitio web, así como en acciones legales según lo permita la ley.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Propiedad Intelectual.</span><br><br>

        Todo el contenido y diseño del sitio web, incluyendo, pero no limitado a texto, gráficos, logotipos,
        imágenes y videos, son propiedad exclusiva del Centro de Viajes Visa Travel Center o están
        licenciados para su uso. Estos elementos están protegidos por las leyes de propiedad intelectual y
        derechos de autor aplicables.<br><br>
        Al acceder y utilizar el sitio web de Visa Travel Center, aceptas respetar los derechos de propiedad
        intelectual del Centro de Viajes y de terceros. Por lo tanto, se prohíbe expresamente la
        reproducción, distribución, modificación o uso no autorizado de cualquier material del sitio web
        para fines comerciales o no comerciales sin el consentimiento previo por escrito del Centro de
        Viajes.<br><br>
        Cualquier uso no autorizado de los materiales del sitio web puede constituir una violación de los
        derechos de autor, marcas comerciales u otros derechos de propiedad intelectual del Centro de
        Viajes o de terceros. El Centro de Viajes se reserva el derecho de emprender acciones legales
        apropiadas contra cualquier individuo o entidad que infrinja estos derechos.<br><br>

        Está estrictamente prohibido publicar o transmitir cualquier contenido en el sitio web del Centro
        de Viajes Visa Travel Center que sea ilegal, difamatorio, obsceno, pornográfico, amenazante,
        violento o que viole los derechos de propiedad intelectual de terceros. Esto incluye, pero no se
        limita a, infringir derechos de autor, marcas comerciales o secretos comerciales.<br><br>

        Al acceder y utilizar el sitio web, aceptas abstenerse de publicar, cargar, transmitir o distribuir
        cualquier material que pueda ser considerado como violación de estas restricciones. El Centro de
        Viajes se reserva el derecho de eliminar cualquier contenido que infrinja estas restricciones y
        tomar las medidas adecuadas, incluida la terminación del acceso al sitio web y la toma de acciones
        legales, según corresponda.<br><br>
        El incumplimiento de estas restricciones de contenido puede resultar en consecuencias legales y
        disciplinarias, de acuerdo con las leyes aplicables y las políticas internas del Centro de Viajes.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Restricciones de Contenido.</span><br><br>

        Todas las interacciones en línea en el sitio web del Centro de Viajes Visa Travel Center deben
        llevarse a cabo de manera respetuosa y cortés hacia otros usuarios. Se espera que los usuarios
        participen en discusiones y comentarios de forma constructiva y sin recurrir al acoso, la
        intimidación, el lenguaje ofensivo o cualquier otra forma de comportamiento inapropiado.<br><br>
        Los comentarios en publicaciones, foros o salas de chat deben contribuir de manera positiva al
        intercambio de ideas y la comunidad en línea. Cualquier forma de conducta irrespetuosa o
        disruptiva será sujeta a medidas disciplinarias, incluida la eliminación de los comentarios y la
        posible suspensión del acceso al sitio web.<br><br>
        El Centro de Viajes Visa Travel Center se reserva el derecho de monitorear y moderar las
        interacciones en línea para garantizar un ambiente seguro y respetuoso para todos los usuarios.
        Los usuarios que violen estas normas pueden enfrentar consecuencias legales y disciplinarias, de
        acuerdo con las políticas internas del Centro de Viajes y las leyes aplicables.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Interacciones en Línea.</span><br><br>
        Todas las interacciones en línea en el sitio web del Centro de Viajes Visa Travel Center deben
        llevarse a cabo de manera respetuosa y cortés hacia otros usuarios. Se espera que los usuarios
        participen en discusiones y comentarios de forma constructiva y sin recurrir al acoso, la
        intimidación, el lenguaje ofensivo o cualquier otra forma de comportamiento inapropiado.<br><br>
        Los comentarios en publicaciones, foros o salas de chat deben contribuir de manera positiva al
        intercambio de ideas y la comunidad en línea. Cualquier forma de conducta irrespetuosa o
        disruptiva será sujeta a medidas disciplinarias, incluida la eliminación de los comentarios y la
        posible suspensión del acceso al sitio web.<br><br>
        El Centro de Viajes Visa Travel Center se reserva el derecho de monitorear y moderar las
        interacciones en línea para garantizar un ambiente seguro y respetuoso para todos los usuarios.
        Los usuarios que violen estas normas pueden enfrentar consecuencias legales y disciplinarias, de
        acuerdo con las políticas internas del Centro de Viajes y las leyes aplicables.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Enlaces a Terceros.</span><br><br>
        El sitio web del Centro de Viajes Visa Travel Center puede contener enlaces a sitios web de
        terceros con el objetivo de proporcionar información adicional o recursos útiles para los usuarios.
        Sin embargo, es importante destacar que el Centro de Viajes no tiene control sobre el contenido o
        las prácticas de privacidad de estos sitios web externos.<br><br>
        Los enlaces a terceros se proporcionan únicamente como una conveniencia para los usuarios, y su
        inclusión no implica un respaldo por parte del Centro de Viajes del contenido o los servicios
        ofrecidos en esos sitios. Por lo tanto, el Centro de Viajes no asume ninguna responsabilidad por la
        exactitud, relevancia o integridad del contenido enlazado, ni por cualquier daño o pérdida que
        pueda surgir del uso de dichos sitios.<br><br>
        Se recomienda encarecidamente a los usuarios revisar las políticas de privacidad individuales y los
        términos de uso de cualquier sitio web de terceros al que accedan a través de los enlaces
        proporcionados en el sitio web del Centro de Viajes. Cada sitio web externo puede tener sus
        propias políticas y prácticas de recopilación de datos, que pueden diferir de las del Centro de
        Viajes.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Modificaciones y Actualizaciones.</span><br><br>
        El Centro de Viajes Visa Travel Center se reserva el derecho de realizar modificaciones en esta
        política de uso del sitio web en cualquier momento y sin previo aviso. Es responsabilidad de los
        usuarios revisar periódicamente esta política para estar al tanto de cualquier actualización o
        cambio.<br><br>
        Los cambios significativos en la política de uso del sitio web se comunicarán de manera oportuna a
        través del propio sitio web, mediante avisos destacados, o por otros medios apropiados, según
        corresponda. Además, el Centro de Viajes puede optar por enviar notificaciones por correo
        electrónico a los usuarios registrados o utilizar otros métodos de comunicación disponibles.
        El uso continuado del sitio web después de la publicación de cambios en esta política constituirá la
        aceptación por parte de los usuarios de dichos cambios. Se recomienda a los usuarios revisar esta
        política regularmente para asegurarse de comprender y cumplir con los términos y condiciones
        más recientes.<br><br>

        <span style="font-weight: 600;  font-size: 22px">Contacto.</span><br><br>
        Si tienes alguna pregunta o inquietud sobre esta política de uso del sitio web, no dudes en ponerte
        en contacto con nosotros a través de nuestro correo electrónico de contacto:
        [ventas&#64;visatravelcenter.com]. Estamos aquí para ayudarte y proporcionarte la información que
        necesites para comprender y cumplir con esta política.<br><br>

        <span style="font-weight: 600; font-size: 30px">Política de Manejo de Datos en el Proceso de Solicitud de Visa</span> <br><br>
        <span style="font-weight: 600;  font-size: 22px">Recopilación de Datos Personales</span><br><br> Durante el proceso de solicitud de visa, se recopilan datos personales, previo consentimiento del titular, como:<br><br>
        ● Información de identidad (nombre, fecha de nacimiento, nacionalidad, entre otros).<br>
        ● Información de contacto (dirección, teléfono, correo electrónico).<br>
        ● Datos de pasaporte y documentos oficiales (número de pasaporte, fecha de emisión, fecha de expiración).<br>
        ● Información sobre educación y propósito del viaje (en este caso, estudiar).<br>
        ● Información económica (excepto cuando no sea necesario recopilarla).<br><br>

        <span style="font-weight: 600; font-size: 22px">Finalidad de la Recopilación de Datos</span><br><br> Los datos personales son recopilados con fines específicos y previamente informados al titular:<br><br>
        ● Evaluar la elegibilidad del solicitante para la visa.<br>
        ● Verificar la identidad y los antecedentes del solicitante.<br>
        ● Asegurar que el solicitante cumpla con los requisitos legales y migratorios.<br>
        ● Confirmar la capacidad económica del solicitante para mantenerse durante su estancia.<br><br>

        <span style="font-weight: 600; font-size: 22px">Protección de Datos</span><br><br> Las autoridades consulares y migratorias, así como Visa Travel Center, están obligadas a proteger los datos personales mediante:<br><br>
        ● <span style="font-weight: 600;">Seguridad digital:</span> Uso de sistemas y tecnologías seguras para almacenar la información.<br>
        ● <span style="font-weight: 600;">Acceso restringido:</span> Solo personal autorizado puede acceder a los datos recopilados.<br>
        ● <span style="font-weight: 600;">Confidencialidad:</span> Los datos no serán compartidos ni utilizados fuera de los fines específicos previamente informados.<br><br>

        <span style="font-weight: 600; font-size: 22px">Nuestro Compromiso</span><br><br>

        En Visa Travel Center <span style="font-weight: 600;">cuidamos la privacidad y seguridad de los datos personales</span> que nos confían. Para ello:<br><br>

        ● Utilizamos sistemas digitales seguros y tecnología avanzada para proteger su información.<br>
        ● Garantizamos que solo personal autorizado acceda a los datos.<br>
        ● Designamos a un responsable de protección de datos para supervisar el cumplimiento de nuestras políticas.<br>
        ● Capacitamos de manera constante a nuestro equipo en la gestión de datos personales.<br>
        ● Permitimos el ejercicio de sus derechos, como acceso, rectificación o eliminación de información, según corresponda.<br>
        ● Revisamos y actualizamos continuamente nuestra política de protección de datos para adaptarnos a los cambios legales y tecnológicos.<br><br>

        <span style="font-weight: 600; font-size: 22px">Transferencia de Datos</span><br><br>

        En ciertos casos, y siempre previo consentimiento del titular, los datos pueden ser compartidos con:<br><br>

        ● <span style="font-weight: 600;">Agencias de seguridad o migración</span> para verificación de antecedentes.<br>
        ● <span style="font-weight: 600;">Entidades gubernamentales u organismos</span> encargados de procesar la visa o controlar la entrada al país.<br><br>

        <span style="font-weight: 600; font-size: 22px">Derechos del Solicitante</span><br><br>

        El titular de los datos personales tiene derecho a:<br><br>

        ● <span style="font-weight: 600;">Acceso:</span> Conocer qué datos se están recopilando y con qué propósito.<br>
        ● <span style="font-weight: 600;">Rectificación:</span> Corregir datos incorrectos o desactualizados.<br>
        ● <span style="font-weight: 600;">Supresión:</span> Solicitar la eliminación de datos, siempre que no existan impedimentos legales para ello.<br>
        ● <span style="font-weight: 600;">Oposición:</span> Rechazar el uso de ciertos datos bajo condiciones específicas.<br>
        <br>

        <span style="font-weight: 600; font-size: 22px">Plazo de Conservación de los Datos</span><br><br>

        Los datos personales se conservarán durante el tiempo necesario para procesar la visa y,
        en algunos casos, un tiempo adicional para cumplir con requisitos legales o administrativos.<br><br>

        <span style="font-weight: 600;">Visa Travel Center reafirma su compromiso con la privacidad y la protección de los
        datos personales de los solicitantes</span>, asegurando un manejo transparente y seguro de la
        información proporcionada.
      </p>
    </div>
  </div>
</div>

<footer class="container-fluid footer-bg footer pb-4">
  <div class="container d-none d-lg-block">
    <div class="row pt-5">
      <div class="col">
        <p class="text-white footer-title">Nuestros locales</p>
        <p class="footer-title mt-4" style="font-size: 13px;">Ecuador</p>
        <a href="https://maps.app.goo.gl/RE5sV2ZULEf1W6ZX8" target="_blank"  class="mt-2" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Bosques del Salado y Rodrigo Chávez G, <span style="font-weight:  bold;">Guayaquil.</span></a><br>
        <a href="https://maps.app.goo.gl/RwHX8cRz31h8cmeW8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight:  bold;">Quito.</span></a><br>
        <a href="https://maps.app.goo.gl/QA3mrf6WD76MdxaS6" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Presidente Córdova y Benigno Malo, <span style="font-weight:  bold;">Cuenca.</span></a><br>

        <p class="footer-title mt-4" style="font-size: 13px;">Colombia</p>
        <a href="https://maps.app.goo.gl/zNKaKv8NDGap8biU8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Edificio Manhattan center, Oficina: 201#15-17, Chapinero, <span style="font-weight:  bold;">Bogotá.</span></a><br>
      </div>
      <div class="col">
        <p class="footer-title">Ventas</p>
        <p style="margin-bottom: 0px;">
          <img
            width="18"
            height="20"
            class="ws-footer"
            src="/assets/images/whatsapp.png"
            alt="visatravelcenter whatsapp"
            style="margin-right: 2px; margin-bottom: 2px"
          />
          <span class="text-white" style="font-weight: 600; font-size: 18px">+593 961 459 059</span>
          <img
          width="15"
          height="15"
          class="ws-footer"
          src="/assets/images/ecuador.png"
          alt="visatravelcenter whatsapp colombia"
          style="margin-left: 5px; margin-bottom: 5px"
        />
        </p>
        <p style="margin-top: 5px;">
          <img
            width="18"
            height="20"
            class="ws-footer"
            src="/assets/images/whatsapp.png"
            alt="visatravelcenter whatsapp"
            style="margin-right: 2px; margin-bottom: 2px"
          />
          <span class="text-white" style="font-weight: 600; font-size: 18px">+57 312 55 01 225</span>
          <img
          width="15"
          height="15"
          class="ws-footer"
          src="/assets/images/colombia.png"
          alt="visatravelcenter whatsapp colombia"
          style="margin-left: 5px; margin-bottom: 5px"
        />
        </p>
        <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
          Sábados de 9 a 13 hs</p>
      </div>
      <div class="col">
        <p class="footer-title">Productos</p>
        <p class="products-list">
          <a href="/home" style="color: white; text-decoration: none">Visas</a>
        </p>
        <p class="products-list">
          <a href="/home" style="color: white; text-decoration: none">Seguros de viajes</a>
          <span></span>
        </p>
        <p class="products-list">
          <a href="/home"style="color: white; text-decoration: none">Paquetes</a>
        </p>
        <a href="/blog" style="text-decoration: none;">
          <p class="text-white footer-title"><span class="font-size-15 bolder-d gris-claro">Blog</span><br /></p>
        </a>
        <a href="/home" style="text-decoration: none;">
          <p class="text-white footer-title"><span class="font-size-15 bolder-d gris-claro">Inicio</span><br /></p>
        </a>
      </div>
      <div class="col">
        <p class="footer-title">Legales</p>
        <p class="text-white" style="font-weight: 400; font-size: 14px">
          Cumplimos con los permisos exigidos. ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP- SEVIFRAL S.A.
        </p>
        <a href="/politicas-privacidad" style="text-decoration: none;">
          <p class="text-white"><span class="font-size-15 bolder-d gris-claro">Políticas de privacidad</span><br /></p>
        </a>
      </div>
      <div class="col">
        <div>
          <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
            <div class="mt-3">
              <span class="mt-4">
                <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-fb.png"
                    alt="visatravelcenter facebook"
                    style="margin-right: 10px;"
                  />
                </a>
                <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-ig.png"
                    alt="visatravelcenter Instagram"
                    style="margin-right: 10px;"
                  />
                </a>
                <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-tk.png"
                    alt="visatravelcenter tiktok"
                    style="margin-right: 10px;"
                  />
                </a> </span>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-lg-none pb-5">
    <div class="col-12 mt-3 text-center">
      <p class="text-white footer-title">Nuestros locales</p>
      <p class="footer-title mt-4" style="font-size: 13px;">Ecuador</p>
      <a href="https://maps.app.goo.gl/RE5sV2ZULEf1W6ZX8" target="_blank"  class="mt-2" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Bosques del Salado y Rodrigo Chávez G, <span style="font-weight:  bold;">Guayaquil.</span></a><br>
      <a href="https://maps.app.goo.gl/RwHX8cRz31h8cmeW8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight:  bold;">Quito.</span></a><br>
      <a href="https://maps.app.goo.gl/QA3mrf6WD76MdxaS6" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Presidente Córdova y Benigno Malo, <span style="font-weight:  bold;">Cuenca.</span></a><br>

      <p class="footer-title mt-4" style="font-size: 13px;">Colombia</p>
      <a href="https://maps.app.goo.gl/zNKaKv8NDGap8biU8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Edificio Manhattan center, Oficina: 201#15-17, Chapinero, <span style="font-weight:  bold;">Bogotá.</span></a><br>
    </div>
    <div class="col-12 text-center mt-4">
      <p class="footer-title">Ventas</p>
    <p style="margin-bottom: 0px;">
      <img
        width="18"
        height="20"
        class="ws-footer"
        src="/assets/images/whatsapp.png"
        alt="visatravelcenter whatsapp"
        style="margin-right: 2px; margin-bottom: 2px"
      />
      <span class="text-white" style="font-weight: 600; font-size: 18px">+593 961 459 059</span>
      <img
      width="15"
      height="15"
      class="ws-footer"
      src="/assets/images/ecuador.png"
      alt="visatravelcenter whatsapp colombia"
      style="margin-left: 5px; margin-bottom: 5px"
    />
    </p>
    <p style="margin-top: 5px;">
      <img
        width="18"
        height="20"
        class="ws-footer"
        src="/assets/images/whatsapp.png"
        alt="visatravelcenter whatsapp"
        style="margin-right: 2px; margin-bottom: 2px"
      />
      <span class="text-white" style="font-weight: 600; font-size: 18px">+57 312 55 01 225</span>
      <img
      width="15"
      height="15"
      class="ws-footer"
      src="/assets/images/colombia.png"
      alt="visatravelcenter whatsapp colombia"
      style="margin-left: 5px; margin-bottom: 5px"
    />
    </p>
    <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
      Sábados de 9 a 13 hs</p>
  </div>
  <div class="col-12 text-center">
    <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
      <span class="mt-4">
        <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-fb.png"
            alt="visatravelcenter facebook"
            style="margin-right: 10px;"
          />
        </a>
        <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-ig.png"
            alt="visatravelcenter Instagram"
            style="margin-right: 10px;"
          />
        </a>
        <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-tk.png"
            alt="visatravelcenter tiktok"
            style="margin-right: 10px;"
          />
        </a> </span>
  </div>
  </div>
</footer>

<div class="container-fluid d-none d-lg-block" style="background-color: #183755">
  <div class="row">
    <div class="col-12 text-white text-center" style="padding-top: 20px; padding-bottom: 20px; font-weight: 300">
        <img
          width="180"
          height="40"
          class="red-social"
          src="assets/images/vtc.png"
          alt="logo vtc"
          style="margin-right: 20px; width: 180px !important; height: auto"
      />
      2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.
    </div>
  </div>
</div>

<div class="container-fluid d-lg-none" style="background-color: #183755">
  <div class="row">
    <div class="col-12 text-white text-center d-none d-lg-block" style="padding-top: 20px; padding-bottom: 20px">
        <img
          width="180"
          height="40"
          class="red-social"
          src="assets/images/vtc.png"
          alt="logo vtc"
          style="margin-right: 20px;"
      />
    </div>
    <div class="col-12 text-white text-center d-block d-lg-none" style="padding-top: 20px; padding-bottom: 20px">
      <img
        width="180"
        height="40"
        class="red-social"
        src="assets/images/vtc.png"
        alt="logo vtc"
        style="margin-right: 20px; height: auto; width: 200px !important"
    />
  </div>
  </div>
  <div class="row">
    <div class="col-12 text-white text-center" style="padding-bottom: 20px; font-weight: 400">
      <span style="font-weight: 300">2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.</span>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" >
    <div class="modal-content" style="border-radius: 24px; padding: 5px 16px">
      <div class="modal-header" style="border-bottom: none;">
        <h3 class="modal-title" id="staticBackdropLabel" style="font-size: 30px; font-family: 'Yeseva One', serif; margin-top: 7px">{{ selectedVisa.name }}</h3>
        <a data-bs-dismiss="modal" aria-label="Close" style="background-color: none; text-decoration: none; cursor: pointer;">x Cerrar</a>
      </div>
      <div class="modal-body">
        {{ selectedVisa.description }}
        @if(currentTab == "visa"){
          <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Completa tus datos y recibe tu asesoría</p>
        }
        @if(currentTab == "paquetes"){
          <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Completa tus datos y reserva tu viaje. Este incluye:</p>
          <div>
            <ul>
              @for (benefit of selectedVisa.benefits; track benefit) {
                <li>{{benefit}}</li>
              }
            </ul>
          </div>
        }
        @if(currentTab == "seguros"){
          <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Viaja protegido. Completa tus datos ahora y cotiza tu plan. Este incluye:</p>
          <div>
            <ul>
              @for (benefit of selectedVisa.benefits; track benefit) {
                <li>{{benefit}}</li>
              }
            </ul>
          </div>
        }
        <div class="row">
          <div class="col-6">
            <img src="/assets/images/form-name.png"alt="Nombre Completo" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <input type="text" class="form-control" placeholder="Nombre Completo" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
          </div>
          <div class="col-6">
            <img src="/assets/images/form-phone.png"alt="telefono" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <input type="text" class="form-control" placeholder="Teléfono (Whatsapp)" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
          </div>
          <div class="col-6">
            <img src="/assets/images/form-people.png" alt="E-mail" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <select class="form-control" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <option value="" disabled selected>¿Viajas sólo o en grupo?</option>
              @for (passenger of passengers; track passenger) {
                <option [value]="passenger">Pasajeros: {{ passenger }}</option>
              }

            </select>
          </div>
          <div class="col-6">
            <img src="/assets/images/form-email.png"alt="E-mail" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <input type="text" class="form-control" placeholder="E-mail" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            @if(currentTab == "visa"){
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Asesoría</button>
            }
            @if(currentTab == "paquetes"){
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Paquete</button>
            }
            @if(currentTab == "seguros"){
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Seguro de Viaje</button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
