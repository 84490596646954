<div class="container-fluid px-0">
  <div class="row mt-4" style="position: absolute; width: 100%">
    <div class="col-6 col-md-5 col-lg-7">
      <a href="/">
        <img
          width="1"
          height="1"
          src="/assets/images/vtc.png"
          alt="logo-prinicpal"
          class="logo-principal"
        />
      </a>
      <h1 style="position: absolute; opacity: 0">Consigue tu visa a cualquier parte del mundo</h1>
    </div>
    <div class="d-none d-md-block col-md-7 col-lg-5 text-white text-right blur-background" style="text-align: right; padding-right: 20px; ">
      <span style="text-align: right; color: white; text-decoration: none;">
      <a href="/blog" style="color: white; text-decoration: none;">
        Blog
        <span style="margin-left: 15px;">|</span>
      </a>
        <img
        width="1"
        height="1"
        src="/assets/images/whatsapp.png"
        alt="whatsappnicpal"
        style="width:16px; height: auto; margin-left: 30px; display: inline-block;"
      />

      +593 961 459 059
      <img
        width="1"
        height="1"
        src="/assets/images/ecuador.png"
        alt="banner-prinicpal"
        style="width:16px; height: auto; margin-top: -5px; margin-right: 10px"
      />
      +57 312 55 01 225
      <img
      width="1"
      height="1"
      src="/assets/images/colombia.png"
      alt="whatsappnicpal"
      style="width:16px; height: auto; margin-top: -5px"
    /></span>
    </div>
    <div class="col-6 d-block d-md-none text-right">
      <a target="_blank" href="https://api.whatsapp.com/send?phone=593961459059&amp;text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20Asesoria%20Migratoria">
        <img
          width="1"
          height="1"
          src="/assets/images/ws-mobile.png"
          alt="contacto whatsapp"
          style="height: 30px; width: auto; margin-top: 5px;"
          class=""
        />
      </a>
    </div>
  </div>
  <img
    width="1"
    height="1"
    src="/assets/images/fondo-final.png"
    alt="banner-prinicpal"
    style="width: 100%; height: auto;"
    class="d-none d-md-block"
  />
  <img
    width="1"
    height="1"
    src="/assets/images/banner-mobile.png"
    alt="banner-prinicpal"
    style="width: 100%; height: auto;"
    class="d-block d-md-none"
  />
</div>

<div class="container mb-5 main-form">
  <ul class="nav nav-tabs" id="myTab" role="tablist" style="margin-left: -12px;">
    <li class="nav-item" role="visa">
      <button class="nav-link active tab-title" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" (click)="currentTabSet('visa')">
        @if(currentTab == "visa"){
        <img
          width="1"
          height="1"
          src="/assets/images/passport.png"
          alt="logo-orbita"
          style="width: 20px; height: auto; cursor: pointer;"
        />
        <span style="color: #136BC0;"> Visas</span>
        }
        @if(currentTab != "visa"){
          Visas
        }
        </button>
    </li>
    <li class="nav-item" role="seguro" style="margin-left: 8px;">
      <button class="nav-link tab-title" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" (click)="currentTabSet('seguros')">
        @if(currentTab == "seguros"){
          <img
            width="1"
            height="1"
            src="/assets/images/shield-check.png"
            alt="logo-orbita"
            style="width: 20px; height: auto; cursor: pointer;"
          />
          <span style="color: #136BC0;"> Seguros de viajes</span>
          }
          @if(currentTab != "seguros"){
            Seguros de viajes
          }
          </button>
    </li>
    <li class="nav-item" role="paquetes" style="margin-left: 8px;">
      <button class="nav-link tab-title" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" (click)="currentTabSet('paquetes')">
        @if(currentTab == "paquetes"){
          <img
            width="1"
            height="1"
            src="/assets/images/suitcase.png"
            alt="logo-orbita"
            style="width: 20px; height: auto; cursor: pointer;"
          />
          <span style="color: #136BC0;"> Paquetes</span>
          }
          @if(currentTab != "paquetes"){
            Paquetes
          }
          </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
      <div class="row pt-4 pb-4 main-tab">
        <div class="col-6 col-md-2">
          <span class="form-title pl-2">Pasaporte
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <select [(ngModel)]="selectedPassport" class="form-select" aria-label="Default select example" class="mt-1 form-control" style="width: 100%; border: none !important; font-size: 16px; color: rgb(75, 75, 75)">
            <option value="" selected>Selecciona tu pasaporte</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Colombia">Colombia</option>
            <option value="Peru">Perú</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Mexico">México</option>
            <option value="República Dominicana">República Dominicana</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Argentina">Argentina</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Honduras">Honduras</option>
            <option value="Panama">Panamá</option>
            <option value="Brasil">Brasil</option>
            <option value="Chile">Chile</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Haiti">Haití</option>
            <option value="Trinidad y Tobago">Trinidad y Tobago</option>
            <option value="El Salvador">El Salvador</option>
          </select>
        </div>
        <div class="col-6 col-md-2">
          <span class="form-title pl-2">Origen
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <select [(ngModel)]="selectedOrigin" class="form-select" aria-label="Default select example" class="mt-1 form-control"  style="width: 100%; border: none !important; font-size: 16px; color: rgb(75, 75, 75)" [(ngModel)]="visas_origin">
            <option value="0" selected>Selecciona tu origen</option>
            @for (country of countries; track country) {
              <option [value]="country['id']">{{ country['name'] }}</option>
            }
          </select>
        </div>
        <div class="col-6 col-md-2">
          <span class="form-title pl-2">Destino
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <select [(ngModel)]="selectedDestiny" class="form-select" aria-label="Default select example" class="mt-1 form-control"  style="width: 100%; border: none !important; font-size: 16px; color: rgb(75, 75, 75)" [(ngModel)]="visas_destiny">
            <option value="0" selected>Selecciona tu destino</option>
            @for (country of countries; track country) {
              <option [value]="country['id']">{{ country['name'] }}</option>
            }
          </select>
        </div>
        <div class="d-none d-md-block col-md-2 text-center mt-4">
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6 vertical-line" style="height: 100%; margin-top: -30px"><br><br><br></div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <span class="form-title pl-2 text-left">Tipo de viaje
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <select [(ngModel)]="selectedType" class="form-select" aria-label="Default select example" class="mt-1 form-control"   style="width: 100%; border: none !important; font-size: 16px; color: rgb(75, 75, 75)" >
            <option value="" selected>Selecciona tu viaje</option>
            <option value="turismo">Turismo</option>
          </select>
        </div>
        <div class="col-12 col-md-2 search-box">
          @if(!visasSearch){
            <img
              width="1"
              height="1"
              src="/assets/images/search.png"
              alt="logo-orbita"
              style="width: 65px; height: auto; cursor: pointer;"
              class="d-none d-md-block"
              (click)="searchVisa('visa')"
            />
            <button class="btn d-block d-md-none mb-3" (click)="searchVisa('visa')" style="width: 80%; background-color: #5ba332; margin-left: auto; margin-right: auto; color: white; margin-top: 20px; padding: 2px; border-radius: 25px">
              <img
              width="1"
              height="1"
              src="/assets/images/search.png"
              alt="logo-orbita"
              style="width: 45px; height: auto; margin-left: -20px"
              />
              <span style="font-weight: bold">Buscar Visa</span>
            </button>
          }
          @if(visasSearch){
            <img
              width="1"
              height="1"
              src="/assets/images/edit.png"
              alt="logo-orbita"
              class="d-none d-md-block"
              style="width: 65px; height: auto; cursor: pointer;"
              (click)="searchVisa('visa')"
            />
            <button class="btn d-block d-md-none mb-3" (click)="searchVisa('visa')" style="width: 80%; background-color: #136bc0; margin-left: auto; margin-right: auto; color: white; margin-top: 20px; padding: 2px; border-radius: 25px">
              <img
              width="1"
              height="1"
              src="/assets/images/edit.png"
              alt="logo-orbita"
              style="width: 45px; height: auto; margin-left: -20px"
              />
              <span style="font-weight: bold">Buscar Visa</span>
            </button>
          }
        </div>
      </div>
      @if(visaError){
        <div>
          <p class="text-danger">Debe especificar todos los campos. Origen y Destino no pueden ser iguales.</p>
        </div>
      }
      @if(visasSearch && !visaSent){
        <div class="row pt-4 pb-4 px-4 form-pane" style="background-color: #2e7dc71e;">
          <div class="col-md-4">
            @if(matchingVisado){
              @if(matchingVisado.tipo == "Turismo" && matchingVisado.visado == "SI" && matchingVisado.requisitos.length > 0){
                <h4 style="color: #136BC0; font-family: 'Yeseva One'">Requisitos de Visa Turista</h4>
              } @else {
                  @if(matchingVisado.requisitos.length < 1){
                    <h4 style="color: #136BC0; font-family: 'Yeseva One'">Te podemos ayudar con tu visado. Consulta con nosotros!</h4>
                  } @else {
                    <h4 style="color: #136BC0; font-family: 'Yeseva One'">No necesitas Visa para este destino, pero recomendamos</h4>
                  }
              }
              <ul>
                @for (requisito of matchingVisado.requisitos; track requisito) {
                  <li>{{ requisito }}</li>
                }
              </ul>
            } @else {
              <h4 style="color: #136BC0; font-family: 'Yeseva One'">Te podemos ayudar con tu visado. Consulta con nosotros!</h4>
            }
          </div>
          <div class="col-md-4 px-4">
            @if(matchingVisado){
              @if(matchingVisado.visado == "SI"){
                @if(matchingVisado.porcentaje == "0.90"){
                  <img height="auto" width="100" src="/assets/images/porcentage-90.png" style="width: 100%;">
                }
                @if(matchingVisado.porcentaje == "0.91"){
                  <img height="auto" width="100" src="/assets/images/porcentage-91.png" style="width: 100%;">
                }
                @if(matchingVisado.porcentaje == "0.92"){
                  <img height="auto" width="100" src="/assets/images/porcentage-92.png" style="width: 100%;">
                }
                @if(matchingVisado.porcentaje == "0.93"){
                  <img height="auto" width="100" src="/assets/images/porcentage-93.png" style="width: 100%;">
                }
                @if(matchingVisado.porcentaje == "0.94"){
                  <img height="auto" width="100" src="/assets/images/porcentage-94.png" style="width: 100%;">
                }
                @if(matchingVisado.porcentaje == "0.95"){
                  <img height="auto" width="100" src="/assets/images/porcentage-95.png" style="width: 100%;">
                }
                @if(matchingVisado.porcentaje == "0.96"){
                  <img height="auto" width="100" src="/assets/images/porcentage-96.png" style="width: 100%;">
                }
                @if(matchingVisado.porcentaje == "0.97"){
                  <img height="auto" width="100" src="/assets/images/porcentage-97.png" style="width: 100%;">
                }
                @if(matchingVisado.porcentaje == "0.98"){
                  <img height="auto" width="100" src="/assets/images/porcentage-98.png" style="width: 100%;">
                }
                @if(matchingVisado.porcentaje == "0.99"){
                  <img height="auto" width="100" src="/assets/images/porcentage-99.png" style="width: 100%;">
                }
              } @else {
                <img height="auto" width="100" src="/assets/images/porcentage-no.png" style="width: 100%;">
              }
            } @else {
              <img height="auto" width="100" src="/assets/images/porcentage-no.png" style="width: 100%;">
            }
          </div>
          <div class="col-md-4 px-4">
            <div>
              <img src="/assets/images/form-name.png"alt="Nombre Completo" style="width: auto; height: 38px; position:absolute;">
              <input type="text" class="form-control" placeholder="Nombre Completo" [(ngModel)]="fullName"  style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <p class="text-danger">{{ fullNameError }} </p>
            </div>
            <div>
              <img src="/assets/images/form-phone.png"alt="telefono" style="width: auto; height: 38px; position:absolute;">
              <input type="text" class="form-control"  [(ngModel)]="phone" placeholder="Teléfono (Whatsapp)" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <p class="text-danger">{{ phoneError }} </p>
            </div>
            <div>
              <img src="/assets/images/form-email.png"alt="E-mail" style="width: auto; height: 38px; position:absolute;">
              <input type="text" class="form-control" [(ngModel)]="email" placeholder="E-mail" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <p class="text-danger">{{ emailError }} </p>
            </div>
            <div>
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none; width: 100%" (click)="sendVisa()">¡Quiero mi primera sesión gratuita!</button>
            </div>
          </div>
        </div>
      }
      @if(visasSearch && visaSent && !loadingMain){
      <div class="row pt-4 pb-4 mt-5 px-4" style="background-color: #2e7dc71e;">
        <div class="col-md-12">
          <h3 style="color: #136BC0;" class="mt-5 text-center mb-5">¡Muchas gracias por tu solicitud {{ fullName }} !</h3>
          <p class="text-center mt-4">Un asesor se pondrá en contacto contigo en breve para conseguir tu Asesoría.</p>
        </div>
      </div>
      }
      @if(visasSearch && visaSent && loadingMain){
      <div class="row pt-4 pb-4 mt-5 px-4 " style="background-color: #2e7dc71e;">
        <div class="col-md-12 text-center">
          <div>
            <div class="loader" style="margin-left: auto; margin-right:auto"></div>
          </div>
        </div>
      </div>
      }
    </div>
    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
      <div class="row pt-4 pb-4 main-tab">
        <div class="col-6 col-md-2">
          <span class="form-title pl-2">Origen
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <select [(ngModel)]="selectedOrigin" class="form-select" aria-label="Default select example" class="mt-1 form-control"  style="width: 100%; border: none !important; font-size: 16px; color: rgb(75, 75, 75)" [(ngModel)]="visas_origin">
            <option value="0" selected>Selecciona tu origen</option>
            @for (country of countries; track country) {
              <option [value]="country['id']">{{ country['name'] }}</option>
            }
          </select>
        </div>
        <div class="col-6 col-md-2">
          <span class="form-title pl-2">Destino
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <select [(ngModel)]="selectedDestiny" class="form-select" aria-label="Default select example" class="mt-1 form-control"  style="width: 100%; border: none !important; font-size: 16px; color: rgb(75, 75, 75)" [(ngModel)]="visas_destiny">
            <option value="0" selected>Selecciona tu destino</option>
            @for (country of countries; track country) {
              <option [value]="country['id']">{{ country['name'] }}</option>
            }
          </select>
        </div>
        <div class="col-6 col-md-2 text-center">
          <div style="width: 100%; text-align: left;">
            <span class="form-title" style="margin-left: 12px;">Edad</span>
          </div>
         <input [(ngModel)]="currentAge" placeholder="Inserte su edad" type="number" style="border:none" class="form-control" maxlength="3">
        </div>
        <div class="col-6 col-md-1 mt-4">
          <div class="d-none d-md-block vertical-line" style="margin-top: -20px"><br><br><br></div>
        </div>
        <div class="col-6 col-md-2 text-center">
          <span class="form-title form-date">Inicio de viaje
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <input [(ngModel)]="initialDate" type="date" class="form-control" style="width: 100%; border: none" placeholder="Inicio de viaje">
        </div>
        <div class="col-6 col-md-2 text-center">
          <span class="form-title form-date">Fin de viaje
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <input [(ngModel)]="finalDate" type="date" class="form-control" style="width: 80%;  border: none" placeholder="fin de viaje">
        </div>
        <div class="col-md-1 text-right">
          @if(!visasSearch){
            <img
              width="1"
              height="1"
              src="/assets/images/search.png"
              alt="logo-orbita"
              style="width: 65px; height: auto; cursor: pointer;"
              class="d-none d-md-block"
              (click)="searchVisa('plan')"
            />
            <button class="btn d-block d-md-none mb-3" (click)="searchVisa('plan')" style="width: 80%; background-color: #5ba332; margin-left: auto; margin-right: auto; color: white; margin-top: 20px; padding: 2px; border-radius: 25px">
              <img
              width="1"
              height="1"
              src="/assets/images/search.png"
              alt="logo-orbita"
              style="width: 45px; height: auto; margin-left: -20px"
              />
              <span style="font-weight: bold">Buscar Seguro</span>
            </button>
          }
          @if(visasSearch){
            <img
              width="1"
              height="1"
              src="/assets/images/edit.png"
              alt="logo-orbita"
              class="d-none d-md-block"
              style="width: 65px; height: auto; cursor: pointer;"
              (click)="searchVisa('plan')"
            />
            <button class="btn d-block d-md-none mb-3" (click)="searchVisa('plan')" style="width: 80%; background-color: #136bc0; margin-left: auto; margin-right: auto; color: white; margin-top: 20px; padding: 2px; border-radius: 25px">
              <img
              width="1"
              height="1"
              src="/assets/images/edit.png"
              alt="logo-orbita"
              style="width: 45px; height: auto; margin-left: -20px"
              />
              <span style="font-weight: bold">Buscar Seguro</span>
            </button>
          }
        </div>
      </div>
      @if(planError){
        <div>
          <p class="text-danger">Debe especificar todos los campos. Origen y Destino no pueden ser iguales.</p>
        </div>
      }
      @if(visasSearch && !visaSent){
        <div class="row pt-4 pb-4 px-4 form-pane" style="background-color: #2e7dc71e;">
          @if(matchingPlan){
            <div class="col-md-8">
              <h4 style="color: #136BC0;">Tu plan recomendado para tu viaje</h4>
              @if(matchingPlan.plan == "Plan ideal"){
                <img height="auto" width="100" src="/assets/images/main-ideal.png" style="width: 100%;">
              }
              @if(matchingPlan.plan == "Plan estandar"){
                <img height="auto" width="100" src="/assets/images/main-estandar.png" style="width: 100%;">
              }
              @if(matchingPlan.plan == "Plan gold"){
                <img height="auto" width="100" src="/assets/images/main-gold.png" style="width: 100%;">
              }
              @if(matchingPlan.plan == "Plan lite"){
                <img height="auto" width="100" src="/assets/images/main-lite.png" style="width: 100%;">
              }
            </div>
          }
          <div class="col-md-4 px-4 mt-4">
            <div>
              <img src="/assets/images/form-name.png"alt="Nombre Completo" style="width: auto; height: 38px; position:absolute;">
              <input type="text" class="form-control" placeholder="Nombre Completo" [(ngModel)]="fullName"  style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <p class="text-danger">{{ fullNameError }} </p>
            </div>
            <div>
              <img src="/assets/images/form-phone.png"alt="telefono" style="width: auto; height: 38px; position:absolute;">
              <input type="text" class="form-control"  [(ngModel)]="phone" placeholder="Teléfono (Whatsapp)" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <p class="text-danger">{{ phoneError }} </p>
            </div>
            <div>
              <img src="/assets/images/form-email.png"alt="E-mail" style="width: auto; height: 38px; position:absolute;">
              <input type="text" class="form-control" [(ngModel)]="email" placeholder="E-mail" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <p class="text-danger">{{ emailError }} </p>
            </div>
            <div >
              <img src="/assets/images/form-people.png" alt="passenger" style="width: auto; height: 38px; position:absolute;">
              <select class="form-control" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px; width: 100%">
                <option value="" disabled selected>¿Viajas sólo o en grupo?</option>
                @for (passenger of passengers; track passenger) {
                  <option [value]="passenger">Pasajeros: {{ passenger }}</option>
                }

              </select>
            </div>
            <div>
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none; width: 100%" (click)="sendVisa()">¡Quiero mi Seguro de Viaje!</button>
            </div>
          </div>
        </div>
      }

      @if(visasSearch && visaSent && !loadingMain){
        <div class="row pt-4 pb-4 mt-5 px-4" style="background-color: #2e7dc71e;">
          <div class="col-md-12">
            <h3 style="color: #136BC0;" class="mt-5 text-center mb-5">¡Muchas gracias por tu solicitud {{ fullName }} !</h3>
            <p class="text-center mt-4">Un asesor se pondrá en contacto contigo en breve para conseguir tu Seguro para viaje.</p>
          </div>
        </div>
        }
        @if(visasSearch && visaSent && loadingMain){
        <div class="row pt-4 pb-4 mt-5 px-4 " style="background-color: #2e7dc71e;">
          <div class="col-md-12 text-center">
            <div>
              <div class="loader" style="margin-left: auto; margin-right:auto"></div>
            </div>
          </div>
        </div>
        }
    </div>
    <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
      <div class="row pt-4 pb-4 main-tab">
        <div class="col-6 col-md-2">
          <span class="form-title pl-2">Origen
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <select class="form-select" aria-label="Default select example" class="mt-1 form-control"  style="width: 100%; border: none !important; font-size: 16px; color: rgb(75, 75, 75)" [(ngModel)]="visas_origin">
            <option value="0" selected>Selecciona tu origen</option>
            @for (country of countries; track country) {
              <option [value]="country['id']">{{ country['name'] }}</option>
            }
          </select>
        </div>
        <div class="col-6 col-md-2">
          <span class="form-title pl-2">Destino
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <select class="form-select" aria-label="Default select example" class="mt-1 form-control"  style="width: 100%; border: none !important; font-size: 16px; color: rgb(75, 75, 75)" [(ngModel)]="visas_destiny">
            <option value="0" selected>Selecciona tu destino</option>
            @for (country of countries; track country) {
              <option [value]="country['id']">{{ country['name'] }}</option>
            }
          </select>
        </div>
        <div class="d-none d-md-block col-md-2 mt-4">
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6 vertical-line" style="height: 100%; margin-top: -30px"><br><br><br></div>
          </div>
        </div>
        <div class="col-6 col-md-2 text-center">
          <span class="form-title form-date">Inicio de viaje
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <input type="date" class="form-control" style="width: 90%; border: none" placeholder="Inicio de viaje">
        </div>
        <div class="col-6 col-md-2 text-center">
          <span class="form-title form-date">Fin de viaje
            <img
            width="1"
            height="1"
            src="/assets/images/chevron-down.png"
            alt="logo-orbita"
            style="width: 12px; height: auto; cursor: pointer;"
          />
          </span>
          <input type="date" class="form-control" style="width: 90%;  border: none" placeholder="fin de viaje">
        </div>
        <div class="col-md-2 text-right">
        @if(!visasSearch){
            <img
              width="1"
              height="1"
              src="/assets/images/search.png"
              alt="logo-orbita"
              style="width: 65px; height: auto; cursor: pointer;"
              class="d-none d-md-block"
              (click)="searchVisa('plan')"
            />
            <button class="btn d-block d-md-none mb-3" (click)="searchVisa('paquete')" style="width: 80%; background-color: #5ba332; margin-left: auto; margin-right: auto; color: white; margin-top: 20px; padding: 2px; border-radius: 25px">
              <img
              width="1"
              height="1"
              src="/assets/images/search.png"
              alt="logo-orbita"
              style="width: 45px; height: auto; margin-left: -20px"
              />
              <span style="font-weight: bold">Buscar Paquete</span>
            </button>
          }
          @if(visasSearch){
            <img
              width="1"
              height="1"
              src="/assets/images/edit.png"
              alt="logo-orbita"
              class="d-none d-md-block"
              style="width: 65px; height: auto; cursor: pointer;"
              (click)="searchVisa('paquete')"
            />
            <button class="btn d-block d-md-none mb-3" (click)="searchVisa('plan')" style="width: 80%; background-color: #136bc0; margin-left: auto; margin-right: auto; color: white; margin-top: 20px; padding: 2px; border-radius: 25px">
              <img
              width="1"
              height="1"
              src="/assets/images/edit.png"
              alt="logo-orbita"
              style="width: 45px; height: auto; margin-left: -20px"
              />
              <span style="font-weight: bold">Buscar Paquete</span>
            </button>
          }
        </div>
      </div>
      @if(visasSearch && !visaSent){
        <div class="row pt-4 pb-4 px-4 form-pane" style="background-color: #2e7dc71e;">
          <div class="col-md-8">
            <h4 style="color: #136BC0;">Paquetes recomendado para tu viaje</h4>
            <img height="auto" width="100" src="/assets/images/paquete-turquia.png" style="width: 100%;">
          </div>
          <div class="col-md-4 px-4">
            <div>
              <img src="/assets/images/form-name.png"alt="Nombre Completo" style="width: auto; height: 38px; position:absolute;">
              <input type="text" class="form-control" placeholder="Nombre Completo" [(ngModel)]="fullName"  style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <p class="text-danger">{{ fullNameError }} </p>
            </div>
            <div>
              <img src="/assets/images/form-phone.png"alt="telefono" style="width: auto; height: 38px; position:absolute;">
              <input type="text" class="form-control"  [(ngModel)]="phone" placeholder="Teléfono (Whatsapp)" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <p class="text-danger">{{ phoneError }} </p>
            </div>
            <div >
              <img src="/assets/images/form-people.png" alt="passenger" style="width: auto; height: 38px; position:absolute;">
              <select class="form-control" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px; width: 100%">
                <option value="" disabled selected>¿Viajas sólo o en grupo?</option>
                @for (passenger of passengers; track passenger) {
                  <option [value]="passenger">Pasajeros: {{ passenger }}</option>
                }

              </select>
            </div>
            <div>
              <img src="/assets/images/form-email.png"alt="E-mail" style="width: auto; height: 38px; position:absolute;">
              <input type="text" class="form-control" [(ngModel)]="email" placeholder="E-mail" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <p class="text-danger">{{ emailError }} </p>
            </div>
            <div>
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none; width: 100%" (click)="sendVisa()">¡Quiero mi primera sesión gratuita!</button>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
@if(visasSearch){
  @if(currentTab == "paquetes"){
    <div class="positioner">
      .
    </div>
  }
  @if(currentTab == "visa"){
    @if(matchingVisado){
      @if(matchingVisado.tipo == "Turismo" && matchingVisado.visado == "SI" && matchingVisado.requisitos.length > 0){
        <div class="positioner2">
          .
        </div>
      }
    } @else {
      <div class="positioner">
        .
      </div>
    }
  }
  @if(currentTab == "seguros"){
    <div class="positioner">
      .
    </div>
  }
}

@if(currentTab == "paquetes"){
<div class="container info info-paquetes">
  <div class="row">
    <div class="col-12">
      <h3 class="visa-title" style="margin-bottom: 0px;">En nuestros paquetes de viaje encuentra</h3>
    </div>
  </div>
<div class="row mt-4">
  <div class="col-12 col-md-3 text-center">
    <img class="image-detail" height="auto" width="100" src="/assets/images/info-paquetes-1.png">
  </div>
  <div class="col-12 col-md-3 text-center">
    <img class="image-detail" height="auto" width="100" src="/assets/images/info-paquetes-2.png">
  </div>
  <div class="col-12 col-md-3 text-center">
    <img class="image-detail" height="auto" width="100" src="/assets/images/info-paquetes-3.png">
  </div>
  <div class="col-12 col-md-3 text-center">
    <img class="image-detail" height="auto" width="100" src="/assets/images/info-paquetes-4.png">
  </div>
</div>
</div>
}

@if(currentTab == "visa"){
  <div class="container info info-visa">
      <div class="row">
        <div class="col-12">
          <h3 class="visa-title" style="margin-bottom: 0px;">Saca la visa con nosotros</h3>
          <p>¡La primera asesoría es gratis!</p>
        </div>
      </div>
    <div class="row">
      <div class="col-12 col-md-3 text-center">
        <img class="image-detail" height="auto" width="100" src="/assets/images/visa-1.png">
      </div>
      <div class="col-12 col-md-3 text-center">
        <img class="image-detail"  height="auto" width="100" src="/assets/images/visa2.png">
      </div>
      <div class="col-12 col-md-3 text-center">
        <img class="image-detail"  height="auto" width="100" src="/assets/images/visa-3.png">
      </div>
      <div class="col-12 col-md-3 text-center">
        <img class="image-detail"  height="auto" width="100" src="/assets/images/visa-4.png">
      </div>
    </div>
  </div>
}

@if(currentTab == "seguros"){
  <div class="container info info-seguros">
    <div class="row">
      <div class="col-12">
        <h3 class="visa-title" style="margin-bottom: 0px;">4 Razones para elegir nuestro seguro de viaje</h3>
      </div>
    </div>
  <div class="row mt-4">
    <div class="col-12 col-md-3 text-center">
      <img class="image-detail" height="auto" width="100" src="/assets/images/info-seguros-1.png">
    </div>
    <div class="col-12 col-md-3 text-center">
      <img class="image-detail" height="auto" width="100" src="/assets/images/info-seguros-2.png">
    </div>
    <div class="col-12 col-md-3 text-center">
      <img class="image-detail" height="auto" width="100" src="/assets/images/info-seguros-3.png">
    </div>
    <div class="col-12 col-md-3 text-center">
      <img class="image-detail" height="auto" width="100" src="/assets/images/info-seguros-4.png">
    </div>
  </div>
  </div>
  }

@if(currentTab == "visa"){
<div class="container mb-3 mt-5">
  <div class="row">
    <div class="col-md-11">
      <h3 class="visa-title">¿A dónde te llevará tu próxima visa?</h3>
      <p>Planea tu próximo viaje y adquiere las mejores ofertas.</p>
    </div>
    <div class="col-md-1 d-none d-md-block" style="margin-left: -20px;">
      <div>
        <button class="carousel-control-prev" type="button" data-bs-target="#visaCarousel" data-bs-slide="prev">
          <img height="auto" width="100" height="auto" src="/assets/images/left-slide.png">
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#visaCarousel" data-bs-slide="next" style="margin-left: -40px !important;">
          <img height="auto" width="100" height="auto" src="/assets/images/right-slide.png">
        </button>
      </div>
    </div>
    <div class="col-md-1 d-none d-md-none" style="margin-left: -20px;">
      <div>
        <button class="carousel-control-prev" type="button" data-bs-target="#visaCarousel-mobile" data-bs-slide="prev">
          <img height="auto" width="100" height="auto" src="/assets/images/left-slide.png">
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#visaCarousel-mobile" data-bs-slide="next" style="margin-left: -40px !important;">
          <img height="auto" width="100" height="auto" src="/assets/images/right-slide.png">
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div id="visaCarousel" class="carousel slide pointer-event d-none d-md-block" data-bs-ride="carousel">
      <div class="carousel-inner">

          @for (visaPart of visasChunks; track visaPart; let idx = $index) {
            <div class="" [ngClass]="{'carousel-item': true, 'active': idx === 0}">
              <div class="row">
                @for (visa of visaPart; track visa; let idxPart = $index) {
                  <div class="col-md-3 text-center" (click)="selectVisa(visa.id, 'visa')" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <img class="d-block w-100" height="auto" width="100" src="{{ visa.image }}" style="width: 100%; cursor: pointer;">
                    <div class="carousel-caption px-0" style="margin-left: -10px !important;">
                      <h5 class="carousel-title" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{ visa.name }}</h5>
                      <p class="carousel-description" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{ visa.description }}</p>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
      </div>
    </div>
    <div id="visaCarousel-mobile" class="carousel slide pointer-event d-block d-md-none" data-bs-ride="carousel">
      <div class="carousel-inner">
          @for (visa of visas; track visa; let idxVisa = $index) {
            <div class="" [ngClass]="{'carousel-item': true, 'active': idxVisa === 0}">
              <div class="row">
                <div class="col-md-12 text-center" (click)="selectVisa(visa.id, 'visa')">
                  <img class="d-block w-100" height="auto" width="100" src="{{ visa.image }}" style="width: 100%; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  <div class="carousel-caption px-0" style="margin-left: -10px !important;">
                    <h5 class="carousel-title">{{ visa.name }}</h5>
                    <p class="carousel-description">{{ visa.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          }
      </div>
    </div>
  </div>
</div>
}

@if(currentTab == "paquetes"){
  <div class="container mb-3 mt-5">
    <div class="row">
      <div class="col-md-11">
        <h3 class="visa-title">Obtén el paquete de viaje que más se adapte a ti</h3>
      </div>
      <div class="col-md-1 d-none d-md-block" style="margin-left: -20px;">
        <div>
          <button class="carousel-control-prev" type="button" data-bs-target="#paquetesCarousel" data-bs-slide="prev">
            <img height="auto" width="100" height="auto" src="/assets/images/left-slide.png">
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#paquetesCarousel" data-bs-slide="next" style="margin-left: -40px !important;">
            <img height="auto" width="100" height="auto" src="/assets/images/right-slide.png">
          </button>
        </div>
      </div>
      <div class="col-md-1 d-none d-md-none" style="margin-left: -20px;">
        <div>
          <button class="carousel-control-prev" type="button" data-bs-target="#paquetesCarousel-mobile" data-bs-slide="prev">
            <img height="auto" width="100" height="auto" src="/assets/images/left-slide.png">
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#paquetesCarousel-mobile" data-bs-slide="next" style="margin-left: -40px !important;">
            <img height="auto" width="100" height="auto" src="/assets/images/right-slide.png">
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
        <div id="paquetesCarousel" class="carousel slide pointer-event d-none d-md-block" data-bs-ride="carousel">
          <div class="carousel-inner">
              @for (paquetePart of paquetesChunks; track paquetePart; let idx = $index) {
                <div class="" [ngClass]="{'carousel-item': true, 'active': idx === 0}">
                  <div class="row">
                    @for (paquete of paquetePart; track paquete; let idxPart = $index) {
                      <div class="col-md-3 text-center" (click)="selectVisa(paquete.id, 'paquetes')" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        <img class="d-block w-100" height="auto" width="100" src="{{ paquete.image }}" style="width: 100%; cursor: pointer;">
                        <div class="carousel-caption px-0" style="margin-left: -10px !important;">
                          <h5 class="carousel-title" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{ paquete.name }}</h5>
                          <p class="carousel-description" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{ paquete.description }}</p>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
          </div>
        </div>
        <div id="paquetesCarousel-mobile" class="carousel slide pointer-event d-block d-md-none" data-bs-ride="carousel">
          <div class="carousel-inner">
              @for (paquete of paquetes; track paquete; let idxVisa = $index) {
                <div class="" [ngClass]="{'carousel-item': true, 'active': idxVisa === 0}">
                  <div class="row">
                    <div class="col-md-12 text-center" (click)="selectVisa(paquete.id, 'seguros')">
                      <img class="d-block w-100" height="auto" width="100" src="{{ paquete.image }}" style="width: 100%; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                      <div class="carousel-caption px-0" style="margin-left: -10px !important;">
                        <h5 class="carousel-title">{{ paquete.name }}</h5>
                        <p class="carousel-description">{{ paquete.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              }
          </div>
        </div>
    </div>
  </div>
}

@if(currentTab == "seguros"){
  <div class="container mb-3 mt-5">
    <div class="row">
      <div class="col-md-11">
        <h3 class="visa-title">Elige nuestro seguro de viaje adaptado a tus necesidades</h3>
      </div>
      <div class="col-md-1 d-none d-md-block" style="margin-left: -20px;">
        <div>
          <button class="carousel-control-prev" type="button" data-bs-target="#segurosCarousel" data-bs-slide="prev">
            <img height="auto" width="100" height="auto" src="/assets/images/left-slide.png">
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#segurosCarousel" data-bs-slide="next" style="margin-left: -40px !important;">
            <img height="auto" width="100" height="auto" src="/assets/images/right-slide.png">
          </button>
        </div>
      </div>
      <div class="col-md-1 d-none d-md-none" style="margin-left: -20px;">
        <div>
          <button class="carousel-control-prev" type="button" data-bs-target="#segurosCarousel-mobile" data-bs-slide="prev">
            <img height="auto" width="100" height="auto" src="/assets/images/left-slide.png">
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#segurosCarousel-mobile" data-bs-slide="next" style="margin-left: -40px !important;">
            <img height="auto" width="100" height="auto" src="/assets/images/right-slide.png">
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div id="segurosCarousel" class="carousel slide pointer-event d-none d-md-block" data-bs-ride="carousel">
        <div class="carousel-inner">
            @for (planPart of planesChunks; track planPart; let idx = $index) {
              <div class="" [ngClass]="{'carousel-item': true, 'active': idx === 0}">
                <div class="row">
                  @for (plan of planPart; track plan; let idxPart = $index) {
                    <div class="col-md-3 text-center" (click)="selectVisa(plan.id, 'seguros')" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                      <img class="d-block w-100" height="auto" width="100" src="{{ plan.image }}" style="width: 100%; cursor: pointer;">
                      <div class="carousel-caption px-0" style="margin-left: -10px !important;">
                        <h5 class="carousel-title" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{ plan.name }}</h5>
                        <p class="carousel-description" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{ plan.description }}</p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
        </div>
      </div>
      <div id="segurosCarousel-mobile" class="carousel slide pointer-event d-block d-md-none" data-bs-ride="carousel">
        <div class="carousel-inner">
            @for (plan of planes; track plan; let idxVisa = $index) {
              <div class="" [ngClass]="{'carousel-item': true, 'active': idxVisa === 0}">
                <div class="row">
                  <div class="col-md-12 text-center" (click)="selectVisa(plan.id, 'seguros')">
                    <img class="d-block w-100" height="auto" width="100" src="{{ plan.image }}" style="width: 100%; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <div class="carousel-caption px-0" style="margin-left: -10px !important;">
                      <h5 class="carousel-title">{{ plan.name }}</h5>
                      <p class="carousel-description">{{ plan.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            }
        </div>
      </div>
    </div>
  </div>
}


<div class="container-fluid px-0 mt-5 d-none d-md-block">
  <img height="auto" width="100" src="/assets/images/reviews.png" style="width: 100%;">
</div>

<div class="container-fluid px-0 mt-5 d-block d-md-none">
  <img height="auto" width="100" src="/assets/images/reviews-mobile.png" style="width: 100%;">
</div>

<footer class="container-fluid footer-bg footer pb-4">
  <div class="container d-none d-lg-block">
    <div class="row pt-5">
      <div class="col">
          <p class="text-white footer-title">Nuestros locales</p>
          <p class="footer-title mt-4" style="font-size: 13px;">Ecuador</p>
          <span class="mt-3" style="font-weight: 300; font-size: 13px; color: white">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight: bold;">Quito.</span><br>
            Santa María y Reina Victoria, <span style="font-weight: bold;">Quito.</span><br>
            La Pinta y Juan León Mera, <span style="font-weight: bold;">Quito.</span><br>
            Vélez y Lizardo García, <span style="font-weight: bold;">Quito.</span><br>
            Bosques del Salado y Rodrigo Chávez G, <span style="font-weight: bold;">Guayaquil.</span><br>
            Presidente Córdova y Benigno Malo, <span style="font-weight: bold;">Cuenca.</span></span>
          <div class="color-primary mt-3 text-center" style="background-color: white; border-radius: 30px; padding: 10px; font-weight: bold; font-size: 13px; width: 80%">Ver locales en Ecuador </div>
      </div>
      <div class="col">
        <p class="footer-title">Ventas</p>
        <p style="margin-bottom: 0px;">
          <img
            width="18"
            height="20"
            class="ws-footer"
            src="/assets/images/whatsapp.png"
            alt="visatravelcenter whatsapp"
            style="margin-right: 2px; margin-bottom: 2px"
          />
          <span class="text-white" style="font-weight: 600; font-size: 18px">+593 961 459 059</span>
          <img
          width="15"
          height="15"
          class="ws-footer"
          src="/assets/images/ecuador.png"
          alt="visatravelcenter whatsapp colombia"
          style="margin-left: 5px; margin-bottom: 5px"
        />
        </p>
        <p style="margin-top: 5px;">
          <img
            width="18"
            height="20"
            class="ws-footer"
            src="/assets/images/whatsapp.png"
            alt="visatravelcenter whatsapp"
            style="margin-right: 2px; margin-bottom: 2px"
          />
          <span class="text-white" style="font-weight: 600; font-size: 18px">+57 312 55 01 225</span>
          <img
          width="15"
          height="15"
          class="ws-footer"
          src="/assets/images/colombia.png"
          alt="visatravelcenter whatsapp colombia"
          style="margin-left: 5px; margin-bottom: 5px"
        />
        </p>
        <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
          Sábados de 9 a 13 hs</p>
      </div>
      <div class="col">
        <p class="footer-title">Productos</p>
        <p class="products-list">
          <span>Visas</span>
        </p>
        <p class="products-list">
          <span>Seguros de viajes</span>
        </p>
        <p class="products-list">
          <span>Paquetes</span>
        </p>
        <a href="https://go.visatravelcenter.com/blog/" style="text-decoration: none;">
          <p class="text-white footer-title"><span class="font-size-15 bolder-d gris-claro">Blog</span><br /></p>
        </a>
      </div>
      <div class="col">
        <p class="footer-title">Legales</p>
        <p class="text-white" style="font-weight: 400; font-size: 14px">
          Cumplimos con los permisos exigidos. ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP- SEVIFRAL S.A.
        </p>
      </div>
      <div class="col">
        <div>
          <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
            <div class="mt-3">
              <span class="mt-4">
                <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-fb.png"
                    alt="visatravelcenter facebook"
                    style="margin-right: 10px;"
                  />
                </a>
                <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-ig.png"
                    alt="visatravelcenter Instagram"
                    style="margin-right: 10px;"
                  />
                </a>
                <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-tk.png"
                    alt="visatravelcenter tiktok"
                    style="margin-right: 10px;"
                  />
                </a> </span>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-lg-none pb-5">
    <div class="col-12 mt-3 text-center">
      <p class="text-white footer-title">Nuestros locales</p>
      <p class="footer-title mt-4" style="font-size: 13px; padding-bottom: 1px">Ecuador</p>
      <span class="mt-3" style="font-weight: 00; font-size: 13px; color: white">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight: bold;">Quito.</span><br>
        Santa María y Reina Victoria, <span style="font-weight: bold;">Quito.</span><br>
        La Pinta y Juan León Mera, <span style="font-weight: bold;">Quito.</span><br>
        Vélez y Lizardo García, <span style="font-weight: bold;">Quito.</span><br>
        Bosques del Salado y Rodrigo Chávez G, <span style="font-weight: bold;">Guayaquil.</span><br>
        Presidente Córdova y Benigno Malo, <span style="font-weight: bold;">Cuenca.</span></span>
      <div class="text-center mt-4">
        <a class="color-primary mt-3 text-center" style="background-color: white; border-radius: 30px; padding: 10px; font-weight: bold; font-size: 13px; width: 80%; text-decoration: none; margin-top: 14px">Ver locales en Ecuador </a>
      </div>
    </div>
    <div class="col-12 text-center mt-4">
      <p class="footer-title">Ventas</p>
    <p style="margin-bottom: 0px;">
      <img
        width="18"
        height="20"
        class="ws-footer"
        src="/assets/images/whatsapp.png"
        alt="visatravelcenter whatsapp"
        style="margin-right: 2px; margin-bottom: 2px"
      />
      <span class="text-white" style="font-weight: 600; font-size: 18px">+593 961 459 059</span>
      <img
      width="15"
      height="15"
      class="ws-footer"
      src="/assets/images/ecuador.png"
      alt="visatravelcenter whatsapp colombia"
      style="margin-left: 5px; margin-bottom: 5px"
    />
    </p>
    <p style="margin-top: 5px;">
      <img
        width="18"
        height="20"
        class="ws-footer"
        src="/assets/images/whatsapp.png"
        alt="visatravelcenter whatsapp"
        style="margin-right: 2px; margin-bottom: 2px"
      />
      <span class="text-white" style="font-weight: 600; font-size: 18px">+57 312 55 01 225</span>
      <img
      width="15"
      height="15"
      class="ws-footer"
      src="/assets/images/colombia.png"
      alt="visatravelcenter whatsapp colombia"
      style="margin-left: 5px; margin-bottom: 5px"
    />
    </p>
    <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
      Sábados de 9 a 13 hs</p>
  </div>
  <div class="col-12 text-center">
    <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
      <span class="mt-4">
        <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-fb.png"
            alt="visatravelcenter facebook"
            style="margin-right: 10px;"
          />
        </a>
        <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-ig.png"
            alt="visatravelcenter Instagram"
            style="margin-right: 10px;"
          />
        </a>
        <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-tk.png"
            alt="visatravelcenter tiktok"
            style="margin-right: 10px;"
          />
        </a> </span>
  </div>
  </div>
</footer>

<div class="container-fluid d-none d-lg-block" style="background-color: #183755">
  <div class="row">
    <div class="col-12 text-white text-center" style="padding-top: 20px; padding-bottom: 20px; font-weight: 300">
        <img
          width="180"
          height="40"
          class="red-social"
          src="assets/images/vtc.png"
          alt="logo vtc"
          style="margin-right: 20px; width: 180px !important; height: auto"
      />
      2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.
    </div>
  </div>
</div>

<div class="container-fluid d-lg-none" style="background-color: #183755">
  <div class="row">
    <div class="col-12 text-white text-center d-none d-lg-block" style="padding-top: 20px; padding-bottom: 20px">
        <img
          width="180"
          height="40"
          class="red-social"
          src="assets/images/vtc.png"
          alt="logo vtc"
          style="margin-right: 20px;"
      />
    </div>
    <div class="col-12 text-white text-center d-block d-lg-none" style="padding-top: 20px; padding-bottom: 20px">
      <img
        width="180"
        height="40"
        class="red-social"
        src="assets/images/vtc.png"
        alt="logo vtc"
        style="margin-right: 20px; height: auto; width: 200px !important"
    />
  </div>
  </div>
  <div class="row">
    <div class="col-12 text-white text-center" style="padding-bottom: 20px; font-weight: 400">
      <span style="font-weight: 300">2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.</span>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" >
    <div class="modal-content" style="border-radius: 24px; padding: 5px 16px">
      <div class="modal-header" style="border-bottom: none;">
        <h3 class="modal-title" id="staticBackdropLabel" style="font-size: 30px; font-family: 'Yeseva One', serif; margin-top: 7px">{{ selectedVisa.name }}</h3>
        <a data-bs-dismiss="modal" aria-label="Close" style="background-color: none; text-decoration: none; cursor: pointer;">x Cerrar</a>
      </div>
      <div class="modal-body">
        {{ selectedVisa.description }}
        @if(currentTab == "visa"){
          <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Completa tus datos y recibe tu asesoría</p>
        }
        @if(currentTab == "paquetes"){
          <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Completa tus datos y reserva tu viaje. Este incluye:</p>
          <div>
            <ul>
              @for (benefit of selectedVisa.benefits; track benefit) {
                <li>{{benefit}}</li>
              }
            </ul>
          </div>
        }
        @if(currentTab == "seguros"){
          <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Viaja protegido. Completa tus datos ahora y cotiza tu plan. Este incluye:</p>
          <div>
            <ul>
              @for (benefit of selectedVisa.benefits; track benefit) {
                <li>{{benefit}}</li>
              }
            </ul>
          </div>
        }
        <div class="row">
          <div class="col-6">
            <img src="/assets/images/form-name.png"alt="Nombre Completo" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <input type="text" class="form-control" placeholder="Nombre Completo" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
          </div>
          <div class="col-6">
            <img src="/assets/images/form-phone.png"alt="telefono" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <input type="text" class="form-control" placeholder="Teléfono (Whatsapp)" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
          </div>
          <div class="col-6">
            <img src="/assets/images/form-people.png" alt="E-mail" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <select class="form-control" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <option value="" disabled selected>¿Viajas sólo o en grupo?</option>
              @for (passenger of passengers; track passenger) {
                <option [value]="passenger">Pasajeros: {{ passenger }}</option>
              }

            </select>
          </div>
          <div class="col-6">
            <img src="/assets/images/form-email.png"alt="E-mail" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <input type="text" class="form-control" placeholder="E-mail" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            @if(currentTab == "visa"){
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Asesoría</button>
            }
            @if(currentTab == "paquetes"){
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Paquete</button>
            }
            @if(currentTab == "seguros"){
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Seguro de Viaje</button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
