import { Component, signal, OnInit } from '@angular/core';
import { Destinations } from '../../resources/destinations';
import { DatePipe } from '@angular/common';
import { Packages } from '../../resources/packages';
import { Plans } from '../../resources/plans';
import { PlanByCountry } from '../../resources/planByCountry';
import { Visas } from '../../resources/visas';
import { VisasEstudio } from '../../resources/visasEstudio';
import { VisasTrabajo } from '../../resources/visasTrabajo';
import { VisasOtros } from '../../resources/visasOtros';
import { CustomerService } from '../../services/customer.service';
import { environment } from '../../../enviroments/environment';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [FormsModule, CommonModule],
  providers: [CustomerService],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})

export class HomeComponent {
  public visas = Destinations.global.visas;
  public paquetes = Packages.global.paquetes;
  public planes = Plans.global.planes;
  public visados = Visas.visados;
  public visadosEstudios = VisasEstudio.visados;
  public visadosOtros = VisasTrabajo.visados;
  public visadosTrabajo = VisasOtros.visados;
  public planesPais = PlanByCountry.planes;
  public selectedVisa = this.visas[0];
  public passengers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public showModal = "";
  public currentTab = 'visa';
  public visasSearch = false;
  public visaSent = false;
  public loadingMain = false;
  public countries = [];
  public visasChunks: any[] = [];
  public paquetesChunks: any[] = [];
  public planesChunks: any[] = [];
  public matchingVisado: any = null;
  public matchingPlan: any = null;
  public matchingPaquete: any = null;
  public allSelect = false;
  public visaError = false;
  public planError = false;
  public paqueteError = false;
  public selectedPassport: string = '';
  public selectedOrigin: string = '';
  public selectedDestiny: string = '';
  public selectedType: string = '';
  public originCountry = null;
  public currentAge = null;
  public initialDate = null;
  public finalDate = null;
  public baseUrl = window.location.href;

  fullName: string = '';
  phone: string = '';
  email: string = '';
  fullNameError: string = '';
  phoneError: string = '';
  emailError: string = '';
  destiny_id: string = '';
  origin_id: string = '';
  type_visa: string = '';
  visas_destiny: string = '0';
  visas_origin: string = '0';

  name = signal('Angular');

  constructor(private customerService: CustomerService) {

  }

  ngOnInit() {
    this.getCountries();
    this.splitVisasIntoChunks();
  }

  getCountries() {
    this.customerService.getCountries().subscribe(
      (resp: any) => {
        this.countries = resp['data'];
      },
      (error) => {
        console.error('Error fetching countries:', error);
      }
    );
  }


  splitVisasIntoChunks() {
    for (let i = 0; i < this.visas.length; i += 4) {
      this.visasChunks.push(this.visas.slice(i, i + 4));
    }
    for (let i = 0; i < this.paquetes.length; i += 4) {
      this.paquetesChunks.push(this.paquetes.slice(i, i + 4));
    }
    for (let i = 0; i < this.planes.length; i += 4) {
      this.planesChunks.push(this.planes.slice(i, i + 4));
    }
  }

  selectVisa(id: number, show: string) {
    if(show === 'visa') {
      this.showModal = 'visa';
      let visa = this.visas.find(visa => visa.id === id);
      if (visa) {
        this.selectedVisa = visa;
      } else {
        console.error(`Visa with id ${id} not found`);
      }
    }
    if(show === 'paquetes') {
      this.showModal = 'paquetes';
      let paquete = this.paquetes.find(paquete => paquete.id === id);
      if (paquete) {
        this.selectedVisa = paquete;
      } else {
        console.error(`Visa with id ${id} not found`);
      }
    }
    if(show === 'seguros') {
      this.showModal = 'seguros';
      let plan = this.planes.find(plan => plan.id === id);
      if (plan) {
        this.selectedVisa = plan;
      } else {
        console.error(`Plan with id ${id} not found`);
      }
    }
  }

  searchVisa(type: string) {
    this.visaError = false;
    this.planError = false;
    this.paqueteError = false;
    this.matchingVisado = null;
    this.matchingPlan = null;
    this.matchingPaquete = null;
    if(type === 'visa') {
      if(this.selectedPassport === '' || this.selectedOrigin === '0' || this.selectedDestiny === '0' || this.selectedType === '') {
        this.visaError = true;
      }else {
        if(this.selectedOrigin == this.selectedDestiny) {
          this.visaError = true;
        }else {
          this.originCountry = null;
          let destinyCountry = null;

          for (let country of this.countries) {
            if (country["id"] == this.selectedOrigin) {
              this.originCountry = country["name"];
            }
            if (country["id"] == this.selectedDestiny) {
              destinyCountry = country;
            }
            if (this.originCountry && destinyCountry) {
              break;
            }
          }

          if (this.originCountry && destinyCountry) {
            this.matchingVisado = this.visados.visas.find(visado =>
              visado.pasaporte.localeCompare(this.selectedPassport, undefined, { sensitivity: 'base' }) === 0 &&
              visado.destino.localeCompare(destinyCountry["name"], undefined, { sensitivity: 'base' }) === 0 &&
              visado.tipo.localeCompare(this.selectedType, undefined, { sensitivity: 'base' }) === 0
            );

            if(this.matchingVisado == null){
              this.matchingVisado = this.visadosTrabajo.visas.find(visado =>
                visado.pasaporte.localeCompare(this.selectedPassport, undefined, { sensitivity: 'base' }) === 0 &&
                visado.destino.localeCompare(destinyCountry["name"], undefined, { sensitivity: 'base' }) === 0 &&
                visado.tipo.localeCompare(this.selectedType, undefined, { sensitivity: 'base' }) === 0
              );
            }

            if(this.matchingVisado == null){
              this.matchingVisado = this.visadosOtros.visas.find(visado =>
                visado.pasaporte.localeCompare(this.selectedPassport, undefined, { sensitivity: 'base' }) === 0 &&
                visado.destino.localeCompare(destinyCountry["name"], undefined, { sensitivity: 'base' }) === 0 &&
                visado.tipo.localeCompare(this.selectedType, undefined, { sensitivity: 'base' }) === 0
              );
            }

            if(this.matchingVisado == null){
              this.matchingVisado = this.visadosEstudios.visas.find(visado =>
                visado.pasaporte.localeCompare(this.selectedPassport, undefined, { sensitivity: 'base' }) === 0 &&
                visado.destino.localeCompare(destinyCountry["name"], undefined, { sensitivity: 'base' }) === 0 &&
                visado.tipo.localeCompare(this.selectedType, undefined, { sensitivity: 'base' }) === 0
              );
            }

            if (this.matchingVisado) {
              this.matchingVisado["requisitosArray"] = [];
              if(this.matchingVisado.requisitos) {
                  const requisitosArray = this.matchingVisado.requisitos.split('\n');
                  this.matchingVisado.requisitosArray = requisitosArray;;
              } else {
                this.matchingVisado.requisitos = [];
              }
              this.visaSent = false;
            } else {
              this.visaSent = false;
            }
          } else {
            this.visaSent = false;
          }

          if(this.visasSearch) {
            this.visaSent = false;
          }
          this.visasSearch = !this.visasSearch ;
        }
      }
    }
    if(type === 'plan') {
      this.visasSearch = false;
      this.visaError = false;
        if(this.selectedOrigin === '0' || this.selectedDestiny === '0' || this.currentAge === null || this.initialDate === null || this.finalDate === null) {
          this.planError = true;
        }else {
          if(this.selectedOrigin == this.selectedDestiny) {
            this.planError = true;
          }else {
          this.originCountry = null;
          let destinyCountry = null;

          for (let country of this.countries) {
            if (country["id"] == this.selectedOrigin) {
              this.originCountry = country["name"];
            }
            if (country["id"] == this.selectedDestiny) {
              destinyCountry = country;
            }
            if (this.originCountry && destinyCountry) {
              break;
            }
          }

          if (this.originCountry && destinyCountry) {
            this.matchingPlan = this.planesPais.find((plan: any) =>
              plan.destino.localeCompare(destinyCountry["name"], undefined, { sensitivity: 'base' }) === 0
            );

            if (this.matchingPlan) {

              if(this.currentAge > this.matchingPlan.maximo) {
                if(this.matchingPlan.destino === 'ESTADOS UNIDOS DE AMERICA') {
                  this.matchingPlan["plan"] = "Plan estandar",
                  this.visaSent = false;
                }else {
                  this.matchingPlan["plan"] = "Plan ideal",
                  this.visaSent = false;
                }

              }
            } else {
              this.matchingPlan = {
                plan: "Plan ideal",
                destino: "No se encontraron planes para el destino seleccionado",
                maximo: 0
              }
              this.visaSent = false;
            }
          } else {
            this.visaSent = false;
          }

          if(this.visasSearch) {
            this.visaSent = false;
          }
          this.visasSearch = !this.visasSearch ;
        }
      }
    }
    if(type === 'paquete') {
      this.visasSearch = false;

      this.visaError = false;
        if(this.selectedOrigin === '0' || this.selectedDestiny === '0' || this.initialDate === null || this.finalDate === null) {
          this.paqueteError = true;
        }else {
          if(this.selectedOrigin == this.selectedDestiny) {
            this.paqueteError = true;
          }else {
          this.originCountry = null;
          let destinyCountry = null;

          for (let country of this.countries) {
            if (country["id"] == this.selectedOrigin) {
              this.originCountry = country["name"];
            }
            if (country["id"] == this.selectedDestiny) {
              destinyCountry = country;
            }
            if (this.originCountry && destinyCountry) {
              break;
            }
          }
          if (this.originCountry && destinyCountry) {
            if(parseInt(this.selectedDestiny) == 235 || parseInt(this.selectedDestiny) == 58 || parseInt(this.selectedDestiny) == 60){
              this.matchingPaquete = this.paquetesChunks[0][6];
            } else if(parseInt(this.selectedDestiny) == 64 || parseInt(this.selectedDestiny)== 71 || parseInt(this.selectedDestiny) == 122){
              this.matchingPaquete = this.paquetesChunks[0][0];
            } else if(parseInt(this.selectedDestiny) == 3 || parseInt(this.selectedDestiny)== 14 || parseInt(this.selectedDestiny) == 187){
              this.matchingPaquete = this.paquetesChunks[0][1];
            } else if(parseInt(this.selectedDestiny) == 188){
              this.matchingPaquete = this.paquetesChunks[0][2];
            } else if(parseInt(this.selectedDestiny) == 44){
              this.matchingPaquete = this.paquetesChunks[0][3];
            } else if(parseInt(this.selectedDestiny) == 57){
              this.matchingPaquete = this.paquetesChunks[0][4];
            } else {
              this.matchingPaquete = null;
            }

          this.visaSent = false;
          } else {
            this.visaSent = false;
          }

          if(this.visasSearch) {
            this.visaSent = false;
          }
          this.visasSearch = !this.visasSearch ;
        }
      }
    }
  }

  currentTabSet(tab: string) {
    this.currentTab = tab;
    this.visasSearch = false;
  }

  scrollToTop(form: string) {
    //this.currentTab = form;

    if (form === 'visa') {
      this.clickOptionByClassName('visa-option');
    }
    if (form === 'seguros') {
      this.clickOptionByClassName('seguro-option');
    }
    if (form === 'paquetes') {
      this.clickOptionByClassName('paquete-option');
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  clickOptionByClassName(className: string) {
    const elements = document.getElementsByClassName(className);
    if (elements.length > 0) {
      const optionElement = elements[0] as HTMLElement;
      console.log('Option Element:', optionElement); // Debugging log
      optionElement.click();
    } else {
      console.error(`Element with class ${className} not found.`);
    }
  }

  sendVisa(origin: string) {
    this.fullNameError = '';
    this.phoneError = '';
    this.emailError = '';

    if (this.fullName.trim() === '') {
      this.fullNameError = 'El nombre completo es requerido.';
      console.error('Full name is required.');
      return;
    }

    if (this.fullName.trim().length < 4) {
      this.fullNameError = 'El nombre completo debe tener al menos 4 caracteres.';
      console.error('Invalid full name. It must be at least 4 characters long.');
      return;
    }

    if (this.fullName.trim().length > 100) {
      this.fullNameError = 'El nombre completo debe tener menos de 100 caracteres.';
      console.error('Invalid full name. It must be no more than 100 characters long.');
      return;
    }

    const fullNamePattern = /^[A-Za-z\s]+$/;
    if (!fullNamePattern.test(this.fullName.trim())) {
      this.fullNameError = 'El nombre completo debe contener solo letras.';
      console.error('Invalid full name. It must contain only letters.');
      return;
    }


    if (this.phone.trim().length < 7) {
      this.phoneError = 'El número de teléfono debe tener al menos 7 dígitos.';
      console.error('Invalid phone number. It must be at least 6 digits long.');
      return;
    }

    if (this.phone.trim().length > 14) {
      this.phoneError = 'El número de teléfono debe tener maximo de 14 dígitos.';
      console.error('Invalid phone number. It must be no more than 14 digits long.');
      return;
    }

    const phonePattern = /^[0-9]+$/;
    if (!phonePattern.test(this.phone.trim())) {
      this.phoneError = 'El número de teléfono debe contener solo números.';
      console.error('Invalid phone number. It must contain only numbers.');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(this.email.trim())) {
      this.emailError = 'La dirección de correo electrónico no es válida.';
      console.error('Invalid email address.');
      return;
    }

    this.visaSent = true;
    this.loadingMain = true;
    let finalName = this.fullName.trimStart()
    let service_type = 'T90';
    let paqueteName = "No encontrado";
    let planName = "No encontrado";
    let visaName = "No encontrado";
    let finalDestiny = this.visas_destiny;
    let finalOrigin = this.visas_origin;
    if(this.matchingPaquete) {
      paqueteName = this.matchingPaquete.name;
    }
    if(this.matchingPlan) {
      planName = this.matchingPlan.plan;
    }
    if(this.matchingVisado) {
      visaName = this.matchingVisado.name;
    }


    if(origin == "primary") {
      if(this.currentTab === 'visa') {
        finalName = this.fullName.trimStart() + ' | ' + "Pasaporte: " + this.selectedPassport.trimStart() + ' | ' + this.originCountry + ' | ' + this.selectedType
        service_type = 'T90';
      }else if(this.currentTab === 'paquetes') {
        finalName = this.fullName.trimStart() + '| origen ' + this.originCountry + ' - Paquete: ' + paqueteName
        service_type = '75';
      }else if(this.currentTab === 'seguros') {
        finalName = this.fullName.trimStart() + ' | Recomendado: ' + planName
        service_type = '76';
      }
    }else{
      finalDestiny = "57"
      finalOrigin = "57"
      if(this.currentTab === 'visa') {
        finalName = this.fullName.trimStart() + ' | Visas';
      }else if(this.currentTab === 'paquetes') {
        finalName = this.fullName.trimStart() + '| Paquetes';
      }else if(this.currentTab === 'seguros') {
        finalName = this.fullName.trimStart() + ' | Seguros'
      }
    }

    this.customerService.postCustomer(environment.serverUrl + '/api/services', {
      destiny_id: finalDestiny,
      origin_id: finalOrigin,
      type: service_type,
      data: {
        tld: 'com',
        name: finalName,
        lastname: "",
        email: this.email.trimStart(),
        phone: this.phone.trimStart(),
        source: window.location.href,
      },
    })
    .subscribe(
      (resp: any) => {
        this.loadingMain = false;
        localStorage.setItem('name', this.fullName.trimStart());
        localStorage.setItem('email', this.email.trimStart());
        localStorage.setItem('phone', this.phone.trimStart());
        localStorage.setItem('source', window.location.href);
        (window as any).dataLayerSend();
      },
      (error: any) => {
        this.loadingMain = false;
        this.visaSent = false;
        alert('Error al enviar la solicitud. Por favor, inténtelo de nuevo. :(');
        console.log(error);
      }
    );
  }

  passportVisas() {
    const passportVisaButton = document.getElementById('passportVisa');
    if (passportVisaButton) {
      passportVisaButton.focus();
    }
  }
}
